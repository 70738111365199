@font-face {
    font-family: 'Roobert';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/Roobert-Regular.otf');
}
@font-face {
    font-family: 'Roobert';
    font-style: italic;
    font-weight: 400;
    src: url('./fonts/Roobert-RegularItalic.otf');
}

@font-face {
    font-family: 'Roobert';
    font-style: normal;
    font-weight: 500;
    src: url('./fonts/Roobert-Medium.otf');
}
@font-face {
    font-family: 'Roobert';
    font-style: italic;
    font-weight: 500;
    src: url('./fonts/Roobert-MediumItalic.otf');
}

@font-face {
    font-family: 'Roobert';
    font-style: normal;
    font-weight: 600;
    src: url('./fonts/Roobert-SemiBold.otf');
}
