@use './variables' as *;

h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 26px;
}

h3 {
    font-size: 30px;
    font-weight: 500;
    line-height: 33px;
}

h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    color: $NeoGreen;
}
