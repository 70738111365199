// Neonomics palette

$NeoGreen: #143232;
$NeoGreenShadesLightL1: #899999;
$NeoGreenShadesLightL2: #5a6f6f;
$NeoGreenShadeLightL3: #2b4747;
$NeoGreenShadesLightL4: #2b4747;

$NeoYellow: #e0f89d;
$NeoYellowShadeL1: #effcce;

$NeoWhiteSmoke: #f4f4f4;
$NeoPurple: #a9a2f9; // Purple
$NeoBlue: #90e4ff; // Blue
$NeoLightblue: #ebfaff; // Light blue
$NeoRed: #e94d3f; // Red
$NeoGhostWhite: #f9fafb; // Ghost White
$NeoWhite: #ffffff;
$NeoRedShadesLightL3: #ff7352;
$NeoDark: #202e3d;
$NeoGreyLight: #e0e0e0;
$NeoLightShade: #dcdcdc;
$NeoSilver: #dcdcdc;
$NeoShadesBlue: #b1ecff;
$NeoBlueLight: #ebfaff;

// snackbar
$NeoSnackbarSuccessBackground: #f6fef9;
$NeoSnackbarSuccessText: #027a48;
$NeoSnackbarSuccessBorder: #6ce9a6;

$NeoSnackbarInfoBackground: #fcfcfd;
$NeoSnackbarInfoText: #344054;
$NeoSnackbarInfoBorder: #d0d5dd;

$NeoSnackbarWarningBackground: #fffcf5;
$NeoSnackbarWarningText: #b54708;
$NeoSnackbarWarningBorder: #fec84b;

$NeoSnackbarDangerBackground: #fffbfa;
$NeoSnackbarDangerText: #b42318;
$NeoSnackbarDangerBorder: #fda29b;

$NeoGrey50: #f9fafb;
$NeoGrey100: #f2f4f7;
$NeoGrey200: #eaecf0;
$NeoGrey300: #d0d5dd;
$NeoGrey500: #667085;
$NeoGrey600: #475467;
$NeoGrey700: #344054;
$NeoGreyNeutral50: #f9fafb;
$NeoGreyNeutral100: #f3f4f6;
$NeoGreyNeutral300: #d2d6db;
$NeoGreyNeutral600: #4d5761;
$NeoBaseBlack: #000000;
$NeoBasePurple: #e9d7fe;

$NeoWhite2: #f9f5ff;

$NeoAlertColorsError800: #912018;
$NeoAlertColorsError700: #b42318;
$NeoAlertColorsError600: #d92d20;
$NeoAlertColorsError500: #f04438;
$NeoAlertColorsError300: #fda29b;
$NeoAlertColorsError200: #fecdca;
$NeoAlertColorsError100: #fee4e2;
$NeoAlertColorsError50: #fef3f2;
$NeoAlertColorsError25: #fffbfa;

$NeoPurple700: #6941c6;
$NeoPurple600: #7f56d9;
$NeoPurple300: #d6bbfb;
$NeoPurple100: #f4ebff;
$NeoPurple50: #f9f5ff;
$NeoPurple25: #fcfaff;

:root {
    --primary-colors-neonomics-green-main: #{$NeoGreen};
    --secondary-colors-gray-neutral-50: #{$NeoGhostWhite};
    --secondary-colors-base-white: #{$NeoWhite};
    --primary-colors-neonomics-yellow-main: #{$NeoYellow};
    --primary-colors-neonomics-green-300: #{$NeoGreenShadeLightL3};
    --primary-colors-neonomics-green-200: #{$NeoGreenShadesLightL2};
    --secondary-colors-gray-neutral-600: #{$NeoGreyNeutral600};
    --secondary-colors-gray-neutral-300: #{$NeoGreyNeutral300};
    --secondary-colors-gray-neutral-100: #{$NeoGreyNeutral100};
    --secondary-colors-gray-neutral-50: #{$NeoGreyNeutral50};
    --gray-700: #{$NeoGrey700};
    --gray-600: #{$NeoGrey600};
    --gray-500: #{$NeoGrey500};
    --gray-300: #{$NeoGrey300};
    --gray-200: #{$NeoGrey200};
    --gray-100: #{$NeoGrey100};
    --gray-50: #{$NeoGrey50};
    --alert-colors-error-800: #{$NeoAlertColorsError800};
    --alert-colors-error-700: #{$NeoAlertColorsError700};
    --alert-colors-error-600: #{$NeoAlertColorsError600};
    --alert-colors-error-500: #{$NeoAlertColorsError500};
    --alert-colors-error-300: #{$NeoAlertColorsError300};
    --alert-colors-error-200: #{$NeoAlertColorsError200};
    --alert-colors-error-100: #{$NeoAlertColorsError100};
    --alert-colors-error-50: #{$NeoAlertColorsError50};
    --alert-colors-error-25: #{$NeoAlertColorsError25};
    --secondary-colors-purple-700: #{$NeoPurple700};
    --secondary-colors-purple-600: #{$NeoPurple600};
    --secondary-colors-purple-300: #{$NeoPurple300};
    --secondary-colors-purple-100: #{$NeoPurple100};
    --secondary-colors-purple-50: #{$NeoPurple50};
    --secondary-colors-purple-25: #{$NeoPurple25};
}
