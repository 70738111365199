@use 'base/fonts';
@use 'base/palette';

h1 {
  font-family: Roobert;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px; /* 126.667% */
}

h3 {
  font-family: Roobert;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 126.667% */
}
