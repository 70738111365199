@font-face {
  font-family: "Roobert";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/Roobert-Regular.otf");
}
@font-face {
  font-family: "Roobert";
  font-style: italic;
  font-weight: 400;
  src: url("../../assets/fonts/Roobert-RegularItalic.otf");
}

@font-face {
  font-family: "Roobert";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/Roobert-Medium.otf");
}
@font-face {
  font-family: "Roobert";
  font-style: italic;
  font-weight: 500;
  src: url("../../assets/fonts/Roobert-MediumItalic.otf");
}

@font-face {
  font-family: "Roobert";
  font-style: normal;
  font-weight: 600;
  src: url("../../assets/fonts/Roobert-SemiBold.otf");
}
