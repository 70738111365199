@use './variables' as *;

.cdk-dialog-container {
    background-color: white;
    border-radius: 24px;
    padding: 40px;
}

.neo-focus {
    position: relative;
    outline: none;
}

.neo-focus:focus-visible::after {
    content: '';
    position: absolute;
    width: calc(100% + 14px);
    height: calc(100% + 10px);
    left: -7px;
    top: -5px;
    border: 1px solid $NeoGreen;
    outline: 2px solid $NeoBlue;
    border-radius: 6px;
}
