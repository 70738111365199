/**
 * COLORS
 * Our design system leverages a purposeful set of color styles that is an important part of the look and feel of the
 * Neonomics brand . When it comes to color, contrast is critical for ensuring text is legible. We've added WCAG 2.1
 * contrast ratios to our color system so you can make sure you're designing with accessibility in mind.
 */

:root {
    /**
   * These are base black and white color styles to quickly swap out if you need to.
   */
    --neoBase-white: #ffffff;
    --neoBase-black: #000000;

    /**
   * PRIMARY COLORS
   * The primary color is your "brand" color, and is used across all interactive elements such as buttons, links, inputs,
   * etc. This color can define the overall feel and can elicit emotion.
   */

    --neoGreen-25: #dbe2e2;
    --neoGreen-50: #b8c1c1;
    --neoGreen-100: #899999;
    --neoGreen-200: #5a6f6f;
    --neoGreen-300: #2b4747;
    --neoGreen-main: #143232;
    --neoGreen-500: #122d2d;
    --neoGreen-600: #102828;
    --neoGreen-700: #0e2323;
    --neoGreen-800: #0a1919;
    --neoGreen-900: #000000;

    --neoYellow-25: #f6fde2;
    --neoYellow-50: #effcce;
    --neoYellow-100: #e9faba;
    --neoYellow-200: #e9f9b1;
    --neoYellow-300: #e3f9a7;
    --neoYellow-main: #e0f89d;
    --neoYellow-500: #cadf8d;
    --neoYellow-600: #b3c67e;
    --neoYellow-700: #9dae6e;
    --neoYellow-800: #707c4f;
    --neoYellow-900: #4d6607;

    /**
   * SECONDARY COLORS
   * Along with primary colors, it's helpful to have a selection of secondary colors to use in components such as pills,
   * alerts and labels. These secondary colors should be used sparingly or as accents, while the primary color(s) should
   * take precedence.
   */

    --neoPurple-25: #fcfaff;
    --neoPurple-50: #f9f5ff;
    --neoPurple-100: #f4ebff;
    --neoPurple-200: #e9d7fe;
    --neoPurple-300: #d6bbfb;
    --neoPurple-main: #b692f6;
    --neoPurple-500: #9e77ed;
    --neoPurple-600: #7f56d9;
    --neoPurple-700: #6941c6;
    --neoPurple-800: #53389e;
    --neoPurple-900: #42307d;

    --neoBlue-25: #f1fcff;
    --neoBlue-50: #e3faff;
    --neoBlue-100: #d5f7ff;
    --neoBlue-200: #c7f5ff;
    --neoBlue-300: #8eebff;
    --neoBlue-main: #90e4ff;
    --neoBlue-500: #5bc4db;
    --neoBlue-600: #44a2b6;
    --neoBlue-700: #226f80;
    --neoBlue-800: #0b4d5b;
    --neoBlue-900: #003c49;

    --neoLightblue-main: #ebfaff;

    --neoGray-100: #f2f4f7;
    --neoGray-200: #eaecf0;
    --neoGray-300: #d0d5dd;
    --neoGray-500: #667085;
    --neoGray-600: #475467;
    --neoGray-700: #344054;
    --neoGray-900: #101828;

    --neoGrayNeutral-25: #fcfcfd;
    --neoGrayNeutral-50: #f9fafb;
    --neoGrayNeutral-100: #f3f4f6;
    --neoGrayNeutral-200: #e5e7eb;
    --neoGrayNeutral-300: #d2d6db;
    --neoGrayNeutral-main: #9da4ae;
    --neoGrayNeutral-500: #6c737f;
    --neoGrayNeutral-600: #4d5761;
    --neoGrayNeutral-700: #384250;
    --neoGrayNeutral-800: #1f2a37;
    --neoGrayNeutral-900: #111927;

    /**
 * ALERT COLORS
 * These colors are the colors used to indicate states and errors and should not be used as anything else.
 */

    /**
   * Error colors are used across error states and in "destructive" actions. They communicate a destructive/negative
   * action, such as removing a user from your team.
   */
    --neoError-25: #fffbfa;
    --neoError-50: #fef3f2;
    --neoError-100: #fee4e2;
    --neoError-200: #fecfca;
    --neoError-300: #fda29b;
    --neoError-main: #f97066;
    --neoError-500: #f04438;
    --neoError-600: #d92d20;
    --neoError-700: #b42318;
    --neoError-800: #912018;
    --neoError-900: #7a271a;

    /**
   * Warning colors can communicate that an action is potentially destructive or "on-hold". These colors are commonly
   * used in confirmations to grab the users' attention.
   */
    --neoWarning-25: #fffcf5;
    --neoWarning-50: #fffaeb;
    --neoWarning-100: #fef0c7;
    --neoWarning-200: #fedf89;
    --neoWarning-300: #fec84b;
    --neoWarning-main: #fdb022;
    --neoWarning-500: #f79009;
    --neoWarning-600: #dc6803;
    --neoWarning-700: #b54708;
    --neoWarning-800: #93370d;
    --neoWarning-900: #7a2e0e;

    /**
   * Success colors communicate a positive action, positive trend, or a successful confirmation. If you're using green as
   * your primary color, it can be helpful to introduce a different hue for your success green.
   */
    --neoSuccess-25: #f6fef9;
    --neoSuccess-50: #ecfdf3;
    --neoSuccess-100: #d1fadf;
    --neoSuccess-200: #a6f4c5;
    --neoSuccess-300: #6ce9a6;
    --neoSuccess-main: #32d583;
    --neoSuccess-500: #12b76a;
    --neoSuccess-600: #039855;
    --neoSuccess-700: #027a48;
    --neoSuccess-800: #05603a;
    --neoSuccess-900: #054f31;

    --theme-color-main: var(--neoGreen-main);
    --theme-color-secondary: var(--neoYellow-main);
    --theme-color-font: var(--neoGreen-main);
    --theme-color-background: var(--neoBase-white);

    --neoFocusRing-color: #9be7ff;

    --neo-warning-bg-color: #feefc6;

    --neo-outline-color: var(--theme-color-main);
    --neo-outline-error-color: var(--neoError-700);
    --neo-primary-color: var(--theme-color-main);
    --neo-primary-font-color: var(--theme-color-font);
    --neo-primary-error-color: var(--neoError-700);
    --neo-primary-bg-color: var(--theme-color-main);
    --neo-primary-error-bg-color: var(--neoError-600);
    --neo-secondary-color: var(--theme-color-secondary);
    --neo-secondary-font-color: var(--theme-color-secondary);
    --neo-secondary-bg-color: var(--theme-color-secondary);

    --neo-input-border-color: var(--neoGreen-50);
    --neo-input-border-focused: var(--neoGrayNeutral-500);
    --neo-input-bg-color: var(--theme-color-background);
    --neo-input-color: var(--theme-color-font);
    --neo-input-placeholder-color: var(--neoGrayNeutral-500);
    --neo-input-outline-color: var(--neo-outline-color);
    --neo-input-icon-color: var(--neoGrayNeutral-500);

    --neo-input-invalid-border-color: var(--neoError-800);
    --neo-input-invalid-bg-color: var(--neoError-50);
    --neo-input-invalid-outline-color: var(--neoError-700);
    --neo-input-invalid-icon-color: var(--neoError-800);
}
